import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  SwipeableDrawer,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { Spacer } from '../../../../common/components/Spacer';
import { MobileMenu } from '../../../../features/Layout/MenuDrawer';
import { LayoutHeaderLink } from '../../../../features/Layout/components';
import type { KcContext } from '../../kcContext';

import { SignUpButton } from './components';

const AccountActionContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled(MuiList)`
  height: calc(100% - 157px);
  overflow: auto;
`;

// MUI list item button이 border를 없애버려서 !important로 강제해야 border가 생김
const ListItem = styled(MuiListItem)`
  border-bottom: 0.5px solid #666973 !important;
`;

const ListItemText = styled(MuiListItemText)<{ $nested?: boolean }>`
  color: white;
  font-weight: bold;
  font-size: 16px;
  ${p => (p.$nested ? 'padding-left: 56px' : '')}
`;

interface MenuProps {
  kcContext: KcContext;
  onNavigate?: () => void;
}
export const KeycloakMenu = ({ kcContext }: MenuProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  const baseUrl =
    (kcContext as KcContext & { client: { baseUrl?: string } }).client
      .baseUrl ?? '';

  return (
    <>
      <AccountActionContainer>
        <LayoutHeaderLink
          onClick={() => (location.href = kcContext.url.loginUrl ?? '/')}>{`${t(
          'headers.links.loginButton',
        )}`}</LayoutHeaderLink>
        <Spacer width={24} />
        {kcContext.pageId === 'login.ftl' ? (
          <SignUpButton kcContext={kcContext} />
        ) : (
          <Spacer width={100} />
        )}
      </AccountActionContainer>
      <List disablePadding>
        <ListItem
          onClick={() => {
            location.href = `${baseUrl}/about`;
          }}>
          <ListItemText
            disableTypography
            primary={`${t('headers.links.aboutLink')}`}
          />
        </ListItem>
        <ListItem onClick={() => ChannelIO('showMessenger')}>
          <ListItemText
            disableTypography
            primary={`${t('headers.links.centerLink')}`}
          />
        </ListItem>
      </List>
    </>
  );
};

interface MenuDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalDrawer?: boolean;
  menus: ReactNode;
}
export const MenuDrawer = (props: MenuDrawerProps) => {
  const { open, onClose, onOpen, modalDrawer = false, menus } = props;

  // set modal zIndex < modal drawer zIndex < snackbar zIndex
  // https://mui.com/material-ui/customization/z-index/
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      sx={{ zIndex: modalDrawer ? 1350 : 1200 }}>
      <MobileMenu onClose={onClose}>{menus}</MobileMenu>
    </SwipeableDrawer>
  );
};
