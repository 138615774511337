import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';

import rightArrowIcon from '../../assets/icons/icon_arrow-right.svg';
import { WindowSize, WindowSizeContext } from '../../hooks/windowSizeHook';

import { ColoredIcon } from './Icon';
import { Spacer } from './Spacer';

const StyledButton = styled.button<{
  $maxWidth?: string;
  $height: string;
  $marginTop?: string;
  $padding?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: ${p => p.$maxWidth ?? '168px'};
  height: ${p => p.$height};
  margin-top: ${p => p.$marginTop ?? 0};
  border-radius: 32px; // 다양한 곡률이 있지만 육안 상 거기서 거기라 중간 사이즈로 통일
  box-sizing: border-box;
  border: none;
  background-color: ${p => '#0051ff' || p.theme.colors.textColorInPrimary};
  color: ${p => p.theme.colors.white};

  line-height: 21px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    background-color: #e8e8e8;
    color: #bdbdbd;
    cursor: not-allowed;
  }
`;

const StyledButtonWithIcon = styled(StyledButton)`
  max-width: ${p => p.$maxWidth ?? '255px'};

  line-height: 28px;
  font-weight: 700;
`;

const StyledThinButton = styled(StyledButton)`
  max-width: ${p => p.$maxWidth ?? '100px'};

  border-radius: 19px;
  font-weight: normal;
`;

// 버튼을 가운데로 정렬하기 위해 일부 사용됨
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface BlueRoundedButtonProps {
  content: string | ReactElement;
  onClick?: () => void;
  disabled?: boolean;
  maxWidth?: string;
  marginTop?: string;
}
export const BlueRoundedButton = (props: BlueRoundedButtonProps) => {
  const { content, onClick, disabled, marginTop, maxWidth } = props;

  const isMobile = useContext(WindowSizeContext) === WindowSize.MOBILE;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      $maxWidth={maxWidth}
      $height={isMobile ? '58px' : '50px'}
      $marginTop={marginTop}>
      {content}
    </StyledButton>
  );
};

export const BlueRoundedButtonWithIcon = (props: BlueRoundedButtonProps) => {
  const { content, onClick, disabled, marginTop, maxWidth } = props;

  return (
    <StyledButtonWithIcon
      onClick={onClick}
      disabled={disabled}
      $maxWidth={maxWidth}
      $height="58px"
      $marginTop={marginTop}>
      {content}
      <Spacer width={10} />
      <ColoredIcon
        width="9px"
        height="20px"
        imageUrl={rightArrowIcon}
        imageWidth="9px"
        color={disabled ? '#bdbdbd' : '#ffffff'}
      />
    </StyledButtonWithIcon>
  );
};

export const BlueRoundedThinButton = (props: BlueRoundedButtonProps) => {
  const { content, onClick, disabled, marginTop, maxWidth } = props;

  return (
    <StyledThinButton
      onClick={onClick}
      disabled={disabled}
      $maxWidth={maxWidth}
      $height="24px"
      $marginTop={marginTop}>
      {content}
    </StyledThinButton>
  );
};
