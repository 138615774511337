import React from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from '../../../../common/components/LanguageSelector';
import { LayoutHeaderLink } from '../../../../features/Layout/components';
import { KcContext } from '../../kcContext';

import { LoginButton, NoUnderlineLink, SignUpButton } from './components';

interface CommonLinksProps {
  kcContext: KcContext;
}
const CommonLinks = ({ kcContext }: CommonLinksProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  const clientBaseUrl = !kcContext.client.baseUrl
    ? location.href
    : kcContext.client.baseUrl;
  const aboutUrl = new URL('about', clientBaseUrl).href;

  return (
    <>
      <NoUnderlineLink href={aboutUrl}>
        <LayoutHeaderLink>{t('headers.links.aboutLink')}</LayoutHeaderLink>
      </NoUnderlineLink>
      <LayoutHeaderLink
        onClick={() => {
          ChannelIO('showMessenger');
        }}>
        {t('headers.links.centerLink')}
      </LayoutHeaderLink>
      <LanguageSelector />
    </>
  );
};

interface KeycloakLayoutHeaderLinksProps {
  kcContext: KcContext;
}
export const KeycloakLayoutHeaderLinks = ({
  kcContext,
}: KeycloakLayoutHeaderLinksProps) => {
  switch (kcContext.pageId) {
    case 'login.ftl':
      return (
        <>
          <CommonLinks kcContext={kcContext} />
          <SignUpButton kcContext={kcContext} />
        </>
      );
    // case 'register.ftl':
    // case 'login-verify-email.ftl':
    // case 'login-update-password.ftl':
    // case 'info.ftl':
    default:
      return (
        <>
          <CommonLinks kcContext={kcContext} />
          <LoginButton kcContext={kcContext} />
        </>
      );
  }
};
