import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logoIcon from '../../assets/icons/icon_logo.png';
import logoIconEn from '../../assets/icons/icon_logo_en.png';
import mobileLogoIcon from '../../assets/icons/icon_mobile-logo.png';
import { ExternalPageContext } from '../../features/Layout/ExternalPageContext';
import { WindowSize, WindowSizeContext } from '../../hooks/windowSizeHook';

import { ColoredIcon } from './Icon';

const NoUnderlineLink = styled(Link)`
  text-decoration: none;
`;
const NoUnderlineAnchor = styled.a`
  text-decoration: none;
`;

/*
const LogoText = styled.span`
  color: white;
  margin-left: 9.5px;
  line-height: 1;
  font-size: 13px;
`;
*/

export const LogoTitle = () => {
  const { external, baseUrl } = useContext(ExternalPageContext);
  const isShrink = WindowSize.NORMAL !== useContext(WindowSizeContext);
  const {
    i18n: { language },
  } = useTranslation();
  const logo = language === 'ko' ? logoIcon : logoIconEn;

  return external ? (
    <NoUnderlineAnchor href={baseUrl}>
      <ColoredIcon
        width={isShrink ? '92px' : '280px'}
        height={isShrink ? '24px' : '32px'}
        imageUrl={isShrink ? mobileLogoIcon : logo}
        imageWidth={isShrink ? '92px' : '280px'}
        color="#ffffff"
      />
    </NoUnderlineAnchor>
  ) : (
    <NoUnderlineLink to={'/'}>
      <ColoredIcon
        width={isShrink ? '92px' : '280px'}
        height={isShrink ? '24px' : '32px'}
        imageUrl={isShrink ? mobileLogoIcon : logo}
        imageWidth={isShrink ? '92px' : '280px'}
        color="#ffffff"
      />
    </NoUnderlineLink>
  );
};
